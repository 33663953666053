.form-check > label {
    display: block;
    position: relative;
    margin-left: -25px;
}

.form-check > label input[type=radio] {
    left: 0;
    margin-top: 7px;
    position: absolute;
}

input[type=radio] {
    transform: scale(2);
}
