.poll-home-page {
    width: 50%;
    height: 50%;
    margin: auto;
}

/*
    For images embedded within a custom poll home page;
    without it the images don't fit within the container.
*/
.poll-home-page img {
    width: 100%;
    height: 100%;
}
