.form-container {
    width: 40%;
    margin: auto;
    display: flex;
    align-items: center;
}

.text-input-container {
    margin: 5px;
}
