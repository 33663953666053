.questions-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.questions-container > * {
    margin-bottom: 40px;
}

.questions-container > *:first-child {
    margin-top: 80px;
}

.questions-container + button {
    margin-bottom: 80px;
}
